@import url(https://fonts.googleapis.com/css?family=Rubik);
body {
  margin: 0;

  font-family: 'Rubik',
    cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
    font-family: "Rubik", cursive;
}

.container {
    text-align: center;
    padding: 15px;
    margin: 10px auto;
}

.csv-input {
    padding: 10px;
    display: block;
    margin: 15px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.button {
    padding: 10px;
    display: block;
    margin: 15px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.container p {
    padding-top: 10px;
    color: #666;
}

